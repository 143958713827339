import { EntityReference, buildCollection } from "firecms";

interface Phase {
  id: string;
  training: EntityReference;
  type: "skill" | "trainingplan";

  // Rest is managed by the Google Sheet importer, this is just necessary to allow creating references
}

export const collectionPhase = buildCollection<Phase>({
  name: "Training Phases",
  path: "phase",
  hideFromNavigation: true,
  permissions: ({ authController }) => ({
    edit: false,
    create: false,
    delete: false,
  }),
  properties: {
    type: {
      name: "Type",
      dataType: "string",
      columnWidth: 128,
      readOnly: true,
    },
    training: ({ values }) => ({
      name: "Training",
      dataType: "reference",
      path: values.type === "skill" ? "skill" : "trainingplan",
      previewProperties: ["name"],
    }),
    id: {
      name: "ID",
      dataType: "string",
      columnWidth: 512,
      readOnly: true,
    },
  },
});
