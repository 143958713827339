import { EnumValues, UploadedFileContext } from "firecms";
import md5 from "js-md5";

/** Appends a hash to the end of the filename to avoid name collisions. */
export async function hashFilename(context: UploadedFileContext): Promise<string> {
  const parts = context.file.name.split(".");
  const basename = parts.slice(0, parts.length - 1);
  const ext = parts[parts.length - 1];
  return `${basename}~${await getHash(context.file)}.${ext}`;
}

/**
 * Filter the reverse mapping to get all the keys that contain a valid enum value.
 * https://stackoverflow.com/questions/48768774/how-to-get-all-the-values-of-an-enum-with-typescript
 * @param someEnum The enum to analyze
 */
export function getEnumKeys(someEnum: GenericEnum<string>): string[] {
  return Object.keys(someEnum).filter((key) => {
    return isNaN(Number(key));
  });
}

/**
 * Builds an enumValues config from a given enum.
 * @param someEnum The enum to convert
 */
export function getEnumValues(someEnum: GenericEnum<string>): EnumValues {
  return getEnumKeys(someEnum).map((key) => {
    return {
      id: key,
      label: key[0].toUpperCase() + key.slice(1),
    };
  });
}

type GenericEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};

// Implement file upload using hashes (via js-md5):
// https://github.com/Camberi/firecms/issues/64
//
async function getHash(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    console.log("Generate Hash...");
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target?.result;
      if (content === null || content === undefined) {
        reject("Couldn't calculate hash.");
        return;
      }
      resolve(md5(content));
    };
    reader.readAsArrayBuffer(file);
  });
}
