import { buildCollection, EntityReference } from "firecms";
import { getIDProperty, getImageProperty, getTranslatedProperty, GSImageUrl, Translations } from "./shared";

interface Goal {
  id: string;
  name: Translations;
  caption: Translations;
  description: Translations<string>;
  thumbnail: GSImageUrl;
  trainingplans: EntityReference[];
  showSkills: boolean;
  questionTrainingplan: Translations;
  trainingStyleStepTitle: Translations;
}

export const collectionGoals = buildCollection<Goal>({
  group: "Static Data",
  name: "Goals",
  path: "goal",
  icon: "SportsScore",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  callbacks: {
    onPreSave: async (props) => {
      return {
        ...props.values,
        id: props.entityId,
      };
    },
  },
  properties: {
    id: getIDProperty(),
    showSkills: {
      name: "Show skills",
      description: "Ask user to select from a list of skills after the user selected this goal?",
      dataType: "boolean",
    },
    thumbnail: getImageProperty({
      collection: "goal",
      name: "Thumbnail",
      description: "The square thumbnail used for ListTiles.",
      width: 512,
      height: 512,
      required: true,
    }),
    name: getTranslatedProperty({ name: "Name" }),
    caption: getTranslatedProperty({ name: "Caption" }),
    description: getTranslatedProperty({ name: "Description" }, { markdown: true }),
    trainingStyleStepTitle: getTranslatedProperty({ name: "Displayed above the question" }),
    questionTrainingplan: getTranslatedProperty({ name: "Question about trainingplan" }),
    trainingplans: {
      name: "Trainingplans",
      description: "A list of training plans that should be displayed after selecting this goal.",
      dataType: "array",
      of: {
        name: "Trainingplan",
        dataType: "reference",
        path: "trainingplan",
      },
      defaultValue: [],
    },
  },
});
