import { User as FirebaseUser } from "firebase/auth";
import { Authenticator, FirebaseCMSApp } from "firecms";
import packageJson from "../package.json";
import { collectionEquipment } from "./collections/equipment";
import { collectionGoals } from "./collections/goal";
import { collectionMusclefocus } from "./collections/musclefocus";
import { collectionPhase } from "./collections/phase";
import { collectionProTip } from "./collections/pro_tip";
import { collectionProduct } from "./collections/product";
import {
  collectionCooldown,
  collectionExtratraining,
  collectionSkill,
  collectionTrainingplan,
  collectionWarmup,
} from "./collections/training";
import { collectionWebsiteFallback } from "./collections/website_fallback";
import logoBlack from "./logo_black.svg";
import logoWhite from "./logo_white.svg";

const firebaseConfig = {
  apiKey: "AIzaSyA4QW4xQ4ZQZNKi0NqW63H0mGaZPLGzy70",
  authDomain: "die-ringe-bodyworkout-app.firebaseapp.com",
  databaseURL: "https://die-ringe-bodyworkout-app.firebaseio.com",
  projectId: "die-ringe-bodyworkout-app",
  storageBucket: "die-ringe-bodyworkout-app.appspot.com",
  messagingSenderId: "874053206460",
  appId: "1:874053206460:web:999e66bf86f6c4e05dabb9",
};

export default function App() {
  const myAuthenticator: Authenticator<FirebaseUser> = async ({ user, authController }) => {
    const token = await user?.getIdTokenResult();
    const role = token?.claims.role;

    if (role !== "editor" && role !== "admin") {
      throw new Error("You are not allowed to access this app.");
    }

    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: [role],
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return (
    <FirebaseCMSApp
      name={`DIE RINGE v${packageJson.version}`}
      authentication={myAuthenticator}
      collections={[
        // Training
        collectionTrainingplan,
        collectionSkill,
        collectionExtratraining,
        collectionWarmup,
        collectionCooldown,

        // Static Data
        collectionGoals,
        collectionEquipment,
        collectionMusclefocus,
        collectionProTip,
        collectionProduct,

        // Website
        collectionWebsiteFallback,

        // Hidden
        collectionPhase,
      ]}
      firebaseConfig={firebaseConfig}
      signInOptions={["google.com", "password"]}
      dateTimeFormat="dd.MMMM.yyyy HH:mm:ss"
      primaryColor="#D54008"
      secondaryColor="#D54008"
      logo={logoBlack}
      logoDark={logoWhite}
    />
  );
}
