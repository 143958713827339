import { buildCollection, EntityReference } from "firecms";
import { getEnumValues } from "../util";
import { getIDProperty, getImageProperty, getTranslatedProperty, GSImageUrl, isOnlyVisibleFor, Translations } from "./shared";

interface Training {
  id: string;
  name: Translations;

  type: TrainingType;

  /** Movivating text on card "Get strong" */
  caption: Translations;

  /** Used in list tiles. "Bodyweight & Gymnastic Rings" */
  descriptionShort: Translations;

  /** Displayed in dialog. */
  descriptionLong: Translations;

  thumbnails: {
    /* Displayed in ListTiles. Unused for warmup/cooldown */
    small: GSImageUrl;

    /* Displayed in the Coach/Discover page. Unused for warmup/cooldown */
    big: GSImageUrl;

    /* Displayed when starting this skill */
    display: GSImageUrl;
  };

  muscleGroupTag: MuscleGroupTag[];

  phases: PhaseMeta[];

  /**  */
  openAccess: boolean;

  requirements: {
    min: EntityReference;
    max: EntityReference;
  }[];

  teaser?: Translations;

  /** Only defined for warmup & cooldown, empty otherwise. */
  suggestedFor: EntityReference[];

  cooperation?: Cooperation;
}

interface Cooperation {
  /** The partner's name */
  name: Translations;

  /** Link to the partner's Instagram, website etc. */
  url: Translations;

  /** The Firebase Storage gs link to the partner's logo */
  logo: string;
}

interface PhaseMeta {
  reference: EntityReference;
  name: Translations;

  // equipmentRequired: EntityReference[];
  // equipmentOptional: EntityReference[];
}

// Copied from @dieringe/shared
// sometimes incorrectly called WorkoutTypeV2
enum TrainingType {
  trainingplan = "trainingplan",
  skill = "skill",
  warmup = "warmup",
  cooldown = "cooldown",
  extratraining = "extratraining",
  custom = "custom",
}

enum MuscleGroupTag {
  lowerBody = "lowerBody",
  upperBodyPush = "upperBodyPush",
  upperBodyPull = "upperBodyPull",
}

function getIcon(type: TrainingType) {
  switch (type) {
    case TrainingType.trainingplan:
      return "FitnessCenter";
    case TrainingType.skill:
      return "SportsGymnastics";
    case TrainingType.warmup:
      return "Whatshot";
    case TrainingType.cooldown:
      return "AcUnit";
    case TrainingType.extratraining:
      return "MoreTime";
    case TrainingType.custom:
      throw new Error("Custom training not implemented");
  }
}

function getCollection(name: string, type: TrainingType) {
  const isWarmupOrCooldown = type === TrainingType.warmup || type === TrainingType.cooldown;
  const isSkillOrTrainingplan = type === TrainingType.trainingplan || type === TrainingType.skill;

  return buildCollection<Training>({
    name: name,
    icon: getIcon(type),
    path: type,
    group: "Trainings",
    customId: true,
    callbacks: {
      onPreSave: async (props) => {
        return {
          ...props.values,
          id: props.entityId,
          type: type,
        };
      },
    },
    properties: {
      id: getIDProperty(),
      name: getTranslatedProperty(
        {
          name: "Name",
          ...isOnlyVisibleFor(!isWarmupOrCooldown),
        },
        {
          validation: {
            required: !isWarmupOrCooldown ? true : false,
          },
        }
      ),
      caption: getTranslatedProperty(
        {
          name: "Caption",
          ...isOnlyVisibleFor(isSkillOrTrainingplan),
        },
        {
          validation: {
            required: isSkillOrTrainingplan ? true : false,
          },
        }
      ),
      descriptionShort: getTranslatedProperty(
        {
          name: "Description Short",
          ...isOnlyVisibleFor(isSkillOrTrainingplan),
        },
        {
          validation: {
            required: isSkillOrTrainingplan ? true : false,
          },
        }
      ),
      descriptionLong: getTranslatedProperty(
        {
          name: "Description Long",
          ...isOnlyVisibleFor(isSkillOrTrainingplan),
        },
        {
          validation: {
            required: isSkillOrTrainingplan ? true : false,
          },
        }
      ),
      thumbnails: {
        dataType: "map",
        properties: {
          small: getImageProperty({
            collection: type,
            name: "Small",
            description: "This is the square preview when displaying this in a small list tile.",
            width: 512,
            height: 512,
            required: isSkillOrTrainingplan,
          }),
          big: getImageProperty({
            collection: type,
            name: "Big",
            description: "This is displayed on the discover page.",
            width: 512,
            height: 640,
            required: isSkillOrTrainingplan,
          }),
          display: getImageProperty({
            collection: type,
            name: "Display",
            description: "This image is displayed at the top when looking at this accessory training.",
            width: 896,
            height: 512,
            required: true,
          }),
        },
      },
      phases: {
        name: "Phases",
        dataType: "array",
        of: {
          name: "Phase",
          dataType: "map",
          properties: {
            reference: {
              name: "Phase Reference",
              dataType: "reference",
              path: "phase",
            },
            name: getTranslatedProperty(
              {
                name: "Name",
                ...isOnlyVisibleFor(type === TrainingType.trainingplan || type === TrainingType.skill),
              },
              {
                validation: {
                  required: false,
                },
              }
            ),
          },
        },
        validation: {
          required: true,
          min: 1,
          max: type === TrainingType.skill || type === TrainingType.trainingplan ? undefined : 1,
        },
      },

      muscleGroupTag: {
        name: "Muscle Group Tags",
        description:
          "Two trainings with the same muscle group tag shouldn't occur on two adjacent days. Can still be done on the same day or with a rest day in between. This is only enforced when using the coach to schedule your trainings.",
        dataType: "array",
        ...isOnlyVisibleFor(!isWarmupOrCooldown),
        of: {
          name: "muscle group tag",
          dataType: "string",
          enumValues: getEnumValues(MuscleGroupTag),
        },
        validation: {
          required: isWarmupOrCooldown ? true : false,
          uniqueInArray: true,
        },
        defaultValue: [],
      },

      openAccess: {
        name: "Open access",
        description: "If this is true, everyone can start this skill, regardless of their subscription status.",
        dataType: "boolean",

        // Extratrainings are always free by default
        defaultValue: type === TrainingType.extratraining,
        ...isOnlyVisibleFor(!isWarmupOrCooldown),
      },

      requirements: {
        name: "Phase requirements",
        description:
          'A list of phase ranges. If the user is in at least one of them, the skill is considered "perfect for your skill level".',
        dataType: "array",
        defaultValue: [],
        ...isOnlyVisibleFor(type === TrainingType.skill),
        validation: {
          required: type === TrainingType.skill,
          min: type === TrainingType.skill ? 1 : 0,
        },
        of: {
          name: "Phase range",
          description: 'Set a phase range where this training is considered "perfect for your skill level".',
          dataType: "map",
          properties: {
            min: {
              name: "Minimum phase id (inclusive)",
              dataType: "reference",
              path: "phase",
              description: "User should be at least in this phase (inclusive).",
            },
            max: {
              name: "Maximum phase id (inclusive)",
              dataType: "reference",
              path: "phase",
              description: "User should be below this phase (inclusive).",
            },
          },
        },
      },

      type: {
        dataType: "string",
        defaultValue: type.toString(),
        enumValues: getEnumValues(TrainingType),
        ...isOnlyVisibleFor(false),
      },

      teaser: {
        ...isOnlyVisibleFor(type === TrainingType.skill),
        ...getTranslatedProperty(
          { name: "Teaser-Video" },
          {
            validation: {
              required: type === TrainingType.skill,
            },
          }
        ),
      },

      suggestedFor: {
        name: "Suggested for phase",
        description: "This accessory session is suggested as a warmup/cooldown before the real workout.",
        dataType: "array",
        ...isOnlyVisibleFor(isWarmupOrCooldown),
        defaultValue: [],
        of: {
          name: "Phase Reference",
          dataType: "reference",
          path: "phase",
        },
        validation: isWarmupOrCooldown
          ? {
              required: true,
              min: 1,
            }
          : undefined,
      },
      cooperation: {
        name: "Cooperation",
        dataType: "map",
        ...isOnlyVisibleFor(type === TrainingType.trainingplan || type === TrainingType.skill),
        validation: {
          required: false,
        },
        properties: {
          logo: getImageProperty({
            collection: type,
            name: "Logo",
            description: "The cooperation logo displayed at the bottom of the WorkoutDetailPage.",
            required: false,
          }),
          name: getTranslatedProperty(
            {
              name: "Name",
            },
            {
              validation: {
                required: false,
              },
            }
          ),
          url: getTranslatedProperty(
            {
              name: "Url",
            },
            {
              validation: {
                required: false,
              },
            }
          ),
        },
      },
    },
  });
}

export const collectionTrainingplan = getCollection("Trainingplan", TrainingType.trainingplan);
export const collectionSkill = getCollection("Skill", TrainingType.skill);
export const collectionWarmup = getCollection("Warmups", TrainingType.warmup);
export const collectionCooldown = getCollection("Cooldowns", TrainingType.cooldown);
export const collectionExtratraining = getCollection("Extra training", TrainingType.extratraining);
