import { buildCollection, EntityReference } from "firecms";
import { getEnumValues } from "../util";
import { getIDProperty, getImageProperty, getTranslatedProperty, GSImageUrl, Translations } from "./shared";

interface EquipmentInfo {
  id: string;
  name: Translations;
  description: Translations;
  thumbnail: GSImageUrl;
  url: Translations;
  canFilter: boolean;
  includesEquipment?: EntityReference[];
}

export enum Equipment {
  none = "none",
  band = "band",
  barbell = "barbell",
  barbell_and_rack = "barbell_and_rack",
  bench = "bench",
  box = "box",
  ceilingmounts = "ceilingmounts",
  dooranchor = "dooranchor",
  dumbbell = "dumbbell",
  dumbbells = "dumbbells",
  footstraps = "footstraps",
  highbar = "highbar",
  kettlebell = "kettlebell",
  kettlebells = "kettlebells",
  lowbar = "lowbar",
  parallettes = "parallettes",
  pbars = "pbars",
  post = "post",
  rings = "rings",
  stick = "stick",
  wall = "wall",
  wallbars = "wallbars",
  weightbelt = "weightbelt",
  weightvest = "weightvest",
}

export const collectionEquipment = buildCollection<EquipmentInfo>({
  customId: getEnumValues(Equipment),
  callbacks: {
    onPreSave: async (props) => {
      const values = props.values;

      // Delete all empty strings from the url map.
      // (FireCMS seems to randomly add those instead of nulls when the field is empty.)
      const url: Translations<string> = {};
      for (const [language, value] of Object.entries(values.url ?? {})) {
        if (value !== "") {
          url[language as keyof Translations] = value;
        }
      }

      return {
        ...values,
        id: props.entityId,
        url,
      };
    },
  },
  group: "Static Data",
  name: "Equipment",
  icon: "SportsTennis",
  path: "equipment",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    id: getIDProperty(),
    thumbnail: getImageProperty({
      collection: "equipment",
      name: "Thumbnail",
      description: "The square thumbnail used for ListTiles.",
      width: 512,
      height: 512,

      // "No equipment" also gets no image, required for the rest though.
      required: false,
    }),
    canFilter: {
      dataType: "boolean",
      name: "Can filter?",
      description: "Set to true if the user should be able to filter for this equipment in the exercise search.",
    },
    name: getTranslatedProperty({ name: "Name" }),
    description: getTranslatedProperty({ name: "Description" }),
    url: getTranslatedProperty(
      { name: "Url", validation: { required: true } },
      {
        validation: {
          required: false,
        },
      }
    ),
    includesEquipment: {
      name: "Includes equipment",
      description:
        "Example: dumbbells (plural) includes a single dumbbell. We use this to filter out equipment that is included in other equipment.",
      dataType: "array",
      of: {
        name: "Equipment",
        dataType: "reference",
        path: "equipment",
      },
    },
  },
});
