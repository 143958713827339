import { buildCollection } from "firecms";
import { getEnumValues } from "../util";
import { getImageProperty, getTranslatedImageProperty, getTranslatedProperty, GSImageUrl, Translations } from "./shared";

interface ProTip {
  /* Displayed in the Discover page. */
  name: Translations;

  /* Displayed in the Discover page. */
  thumbnailUrl: GSImageUrl;

  slides: ProTipSlide[];

  isPublished: boolean;
  urgency: Urgency;
  targeting?: ProTipTargeting;
}

interface ProTipSlide {
  body: Translations;
  imageUrl?: Translations<GSImageUrl>;
  videoUrl?: Translations;
}

interface ProTipTargeting {
  weekStreakMin?: number;
  weekStreakMax?: number;
  phases?: string[];
  trial: boolean;
}

enum Urgency {
  immediate = "immediate",
  high = "high",
  medium = "medium",
  low = "low",
}

export const collectionProTip = buildCollection<ProTip>({
  group: "Static Data",
  name: "ProTip",
  icon: "TipsAndUpdates",
  path: "pro_tip",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    isPublished: {
      name: "Is published",
      description: "Set to true if this pro tip should be shown on the discover page.",
      dataType: "boolean",
    },
    name: getTranslatedProperty({ name: "Name" }, { validation: { required: true } }),
    thumbnailUrl: getImageProperty({
      collection: "pro_tip",
      name: "Thumbnail",
      description: "The thumbnail is displayed on the discover page.",
      width: 512,
      height: 640,
      required: true,
    }),
    urgency: {
      name: "Urgency",
      description: "The urgency is used to determine the order of pro tips on the discover page.",
      dataType: "string",
      enumValues: getEnumValues(Urgency),
      validation: {
        required: true,
      },
    },
    targeting: {
      name: "Targeting",
      description: "Targeting is used to determine which pro tips show up on the discover page.",
      dataType: "map",
      validation: {
        required: false,
      },
      properties: {
        weekStreakMin: {
          name: "Minimum week streak",
          dataType: "number",
        },
        weekStreakMax: {
          name: "Maximum week streak",
          dataType: "number",
        },
        phases: {
          name: "Phases",
          description: "The list of phases this pro tip is relevant for.",
          dataType: "array",
          of: {
            dataType: "reference",
            path: "phase",
            forceFilter: {
              type: ["in", ["skill", "trainingplan"]],
            },
          },
        },
        trial: {
          name: "Trial",
          description: "Set to true if this pro tip is only relevant for the trial phase.",
          dataType: "boolean",
          defaultValue: false,
          validation: {
            required: true,
          },
        },
      },
    },
    slides: {
      name: "Slides",
      description: "The list of slides/pages in a single pro tip.",
      dataType: "array",
      validation: {
        required: true,
        min: 1,
      },
      of: {
        dataType: "map",
        properties: {
          body: getTranslatedProperty(
            { name: "Body text" },
            {
              markdown: true,
            }
          ),
          image: getTranslatedImageProperty(
            { name: "Image" },
            {
              collection: "pro_tip/slide",
              width: 768,
              height: 1024,
              required: false,
            }
          ),
          video: getTranslatedProperty(
            { name: "Video", validation: { required: true } },
            {
              validation: {
                required: false,
              },
            }
          ),
        },
      },
    },
  },
});
