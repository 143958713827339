import { buildCollection } from "firecms";
import { getImageProperty, getTranslatedProperty, GSImageUrl, Translations } from "./shared";

interface ProductInfo {
  name: Translations;
  photos: GSImageUrl[];
  infoBannerText: Translations;
  description: Translations;
  url: Translations;
  isActive: boolean;
}

export const collectionProduct = buildCollection<ProductInfo>({
  callbacks: {
    onPreSave: async (props) => {
      const values = props.values;

      // Delete all empty strings from the url map.
      // (FireCMS seems to randomly add those instead of nulls when the field is empty.)
      const url: Translations<string> = {};
      for (const [language, value] of Object.entries(values.url ?? {})) {
        if (value !== "") {
          url[language as keyof Translations] = value;
        }
      }

      return {
        ...values,
        id: props.entityId,
        url,
      };
    },
  },
  group: "Static Data",
  name: "Products",
  icon: "ShoppingCart",
  path: "product",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    isActive: {
      dataType: "boolean",
      name: "Is active?",
      description: "Set to true if we want to display this product in the app.",
    },
    name: getTranslatedProperty({ name: "Name" }),
    infoBannerText: getTranslatedProperty(
      { name: "Info banner text" },
      {
        validation: {
          required: false,
        },
      }
    ),
    description: getTranslatedProperty(
      { name: "Description" },
      {
        markdown: true,
      }
    ),
    photos: {
      dataType: "array",
      name: "Photos",
      of: getImageProperty({
        collection: "product",
        name: "Photo",
        description: "A high resolution product photo for the detail page.",
        width: 896,
        height: 896,
        required: true,
      }),
    },
    url: getTranslatedProperty(
      { name: "Url", validation: { required: true } },
      {
        validation: {
          required: false,
        },
      }
    ),
  },
});
