import { buildCollection } from "firecms";
import { isOnlyVisibleFor } from "./shared";

enum FallbackType {
  missing = "missing",
  redirect = "redirect",
}

enum HttpStatusRedirect {
  movedPermanently = 301,
  found = 302,
}

type WebsiteFallbackRedirect = {
  comment: string;
  type: FallbackType.redirect;
  path: string;
  to: string;

  /**
   * The type of redirect as a HTTP status code.
   *
   * 301: Permanent redirect
   * 302: Temporary redirect
   */
  status: HttpStatusRedirect;

  created: Date;
  updated: Date;
};

enum HttpStatusMissing {
  notFound = 404,
  gone = 410,
}

type WebsiteFallbackMissing = {
  comment: string;
  type: FallbackType.missing;
  path: string;

  /**
   * The HTTP status code.
   *
   * 404: Not found
   * 410: Gone
   */
  status: HttpStatusMissing;

  created: Date;
  updated: Date;
};

export type WebsiteFallback = WebsiteFallbackRedirect | WebsiteFallbackMissing;

export const collectionWebsiteFallback = buildCollection<WebsiteFallback>({
  name: "Website Fallback",
  description: "Define fallbacks for missing or moved pages.",
  defaultSize: "xs",
  group: "Website",
  path: "website_fallback",
  icon: "Http",
  callbacks: {
    onIdUpdate: ({ values }) => getDocumentIdFromSlug(values.path),
    onPreSave: ({ values }) => {
      if (values.status === HttpStatusMissing.notFound) values.type = FallbackType.missing;
      if (values.status === HttpStatusMissing.gone) values.type = FallbackType.missing;

      if (values.status === HttpStatusRedirect.movedPermanently) values.type = FallbackType.redirect;
      if (values.status === HttpStatusRedirect.found) values.type = FallbackType.redirect;

      // Prevent "to" if missing
      if (values.type === FallbackType.missing) {
        if ((values as unknown as WebsiteFallbackRedirect).to) {
          throw new Error("If the page is missing, leave 'Redirect To' empty.");
        }
      }

      if (values.type === FallbackType.redirect) {
        // Ensure "to" if redirect
        if ((values.to ?? "").length === 0) {
          throw new Error("If the page is a redirect, 'Redirect To' is required.");
        }

        // Ensure "to" starts with "/" or "http"
        const to = (values.to ?? "");
        if (!to.startsWith("/") && !to.startsWith("http")) {
          throw new Error("Redirects must start with a slash or http. Example: Redirect to /de/privacy instead of de/privacy");
        }
      }

      return values;
    },
  },
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    path: {
      name: "Path",
      description: "No starting slash, e.g. de/some/path",
      validation: { required: true },
      dataType: "string",
    },
    status: {
      name: "HTTP Status Code",
      description: "This determines if the page is missing or a redirect and how it's handled by the browser & Google.",
      dataType: "number",
      validation: { required: true },
      enumValues: [
        {
          id: HttpStatusRedirect.movedPermanently,
          label: "Redirect: Moved Permanently",
          color: "greenDark",
        },
        {
          id: HttpStatusRedirect.found,
          label: "Redirect: Moved Temporarily",
          color: "greenLighter",
        },
        {
          id: HttpStatusMissing.notFound,
          label: "Missing: Not Found",
          color: "redDark",
        },
        {
          id: HttpStatusMissing.gone,
          label: "Missing: Intentionally Removed",
          color: "redLighter",
        },
      ],
    },
    to: {
      name: "Redirect To",
      dataType: "string",
      description:
        "The path to redirect to. If the page is missing, leave this empty as it's ignored. Must start with a slash (e.g. '/de/privacy' instead of 'de/privacy'). Alternatively, it can be an external url starting with \"http\".",
    },
    comment: {
      name: "Comment",
      description: "Internal note about why this fallback exists.",
      validation: { required: true },
      dataType: "string",
    },
    type: {
      name: "Type (determined automatically)",
      dataType: "string",
      ...isOnlyVisibleFor(false),
    },
    created: {
      name: "Timestamp: created",
      dataType: "date",
      autoValue: "on_create",
      readOnly: true,
      ...isOnlyVisibleFor(false),
    },
    updated: {
      name: "Timestamp: updated",
      dataType: "date",
      autoValue: "on_create",
      readOnly: true,
      ...isOnlyVisibleFor(false),
    },
  },
});

/**
 * ! keep in sync with the implementation of this function in the dieringe repository.
 * Slugs can contain slashes. Document IDs can't.
 * This function converts a slug to a document id.
 * NOTE: Do not modify this code in a backwards incompatible way, or it will break all redirects on the website.
 *
 * @param slug The slug to get the document id for, e.g. "en/about-us"
 */
function getDocumentIdFromSlug(slug: string) {
  return slug.replace(/\//g, "##");
}
